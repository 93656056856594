import React from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import Icon from '@/components/Icon'
import BaseImageV2 from '@/v2/base/BaseImageV2'

type Props = {
  blok: SbBlokData & ISbChildrenReviewItemV2
}

const ItemSectionReviewsCardV2 = ({ blok }: Props) => {
  const [open, setOpen] = React.useState(false)

  const halfStarsCount = blok.stars * 2

  function toggleOpen() {
    setOpen(!open)
  }

  function isIndexOdd(number: number) {
    return (number + 1) % 2 !== 0
  }

  return (
    <div className="" {...storyblokEditable(blok)}>
      {blok.logo?.filename ? (
        <div className="mb-8 flex items-center">
          <BaseImageV2
            src={blok.logo?.filename}
            alt={blok.logo?.alt}
            breakPointsWidthMap={{ 0: 64 }}
            className="h-12 w-[167px] [&_img]:h-full [&_img]:w-full"
          />
        </div>
      ) : (
        !blok.stars_hidden && (
          <div className="mb-6 flex flex-col gap-1">
            <div className="mb-1 flex items-center md:mb-2">
              {Array.from(
                { length: halfStarsCount },
                (_, i) =>
                  (!isIndexOdd(i) || (isIndexOdd(i) && i + 1 === halfStarsCount)) && (
                    <Icon key={i} name={isIndexOdd(i) ? 'star-half' : 'star'} className="h-6 w-6" />
                  ),
              )}
            </div>
            {blok.stars_text && <span className="caption-default block text-v2-text-secondary">{blok.stars_text}</span>}
          </div>
        )
      )}
      {blok.title && <span className="heading-5 mb-3 block text-v2-text-primary">{blok.title}</span>}
      <blockquote className="body-1 mb-8 whitespace-pre-line text-v2-text-primary md:mb-10">
        {blok.text}

        <span className={`${open ? 'block' : 'hidden'}`}>
          <br />
          {blok.text_hidden}
        </span>

        {blok.text_hidden ? (
          <div
            onClick={() => {
              toggleOpen()
            }}
            className={`body-2-bold mt-1.5 cursor-pointer ${open ? 'hidden' : 'block'}`}
          >
            + {blok.text_hidden_cta}
          </div>
        ) : null}
      </blockquote>
    </div>
  )
}

export default ItemSectionReviewsCardV2
