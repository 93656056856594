import React, { useState, useEffect } from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import ItemSectionHeadV2 from '@/v2/storyblok/items/ItemSectionHeadV2'
import Icon from '@/components/Icon'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import ItemIconButtonV2 from '../items/ItemIconButtonV2'
import UtilButtonV2 from '../utils/UtilButtonV2'
import ItemSectionReviewsCardV2 from '../items/ItemSectionReviewsCardV2'
import UtilLinkV2 from '../utils/UtilLinkV2'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import classNames from 'classnames'

type Props = {
  blok: SbBlokData & ISbSectionReviewsV2
}

const SectionReviewsV2 = ({ blok }: Props) => {
  const itemsLength = blok.items.length
  const isSliderSingle = itemsLength === 1
  const isSlideDesktopDisabled = itemsLength < 3

  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const options: SwiperOptions = {
    preventInteractionOnTransition: true,
    centerInsufficientSlides: true,
    grabCursor: true,
    slidesPerView: isSliderSingle ? 1 : 1.2,
    navigation: false,
    spaceBetween: 28,
    breakpoints: {
      768: {
        slidesPerView: isSliderSingle ? 1 : 2,
        spaceBetween: 32,
      },
      1024: {
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
        grabCursor: !isSlideDesktopDisabled,
        allowTouchMove: !isSlideDesktopDisabled,
        slidesPerView: isSliderSingle ? 1 : 2,
        spaceBetween: 32,
      },
    },
    modules: [Navigation, Pagination],
  }

  const nextSlide = () => {
    if (swiperInstance) {
      swiperInstance.slideNext()
    }
  }

  const prevSlide = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev()
    }
  }

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.on('slideChange', () => {
        setIsBeginning(swiperInstance.isBeginning)
        setIsEnd(swiperInstance.isEnd)
      })
    }
  }, [swiperInstance])

  return (
    <div className="py-16 lg:py-24" {...storyblokEditable(blok)}>
      {/* Top */}
      <div className="container-v2 mx-auto mb-14 md:mb-16 md:px-4">
        {!!blok.head?.[0] && (
          <div className="mb-8 md:mb-10">
            <ItemSectionHeadV2 blok={blok.head[0]} />
          </div>
        )}
        <div className="flex justify-between gap-6 md:justify-start md:gap-32">
          {/* Logo Image */}
          {blok.top_logo?.filename ? (
            <div className="flex items-center">
              <BaseImageV2
                src={blok.top_logo.filename}
                alt={blok.top_logo.alt}
                breakPointsWidthMap={{ 0: 64 }}
                className="h-24 w-auto lg:w-52 [&_img]:h-full [&_img]:w-full"
              />
            </div>
          ) : (
            <div className="flex flex-col gap-3">
              {!blok.top_google_hide_stars && (
                <div className="flex">
                  {Array.from({ length: 5 }, (_, i) => (
                    <Icon key={i} name="star-dark" className="h-8 w-8" />
                  ))}
                </div>
              )}
              {!!(blok.top_google_stars || blok.top_google_logo?.filename) && (
                <div className="flex items-center gap-2 text-v2-text-primary">
                  {blok.top_google_logo?.filename && (
                    <BaseImageV2
                      src={blok.top_google_logo.filename}
                      alt={blok.top_google_logo.alt}
                      breakPointsWidthMap={{ 0: 24 }}
                    />
                  )}
                  {blok.top_google_stars && <span className="heading-5">{blok.top_google_stars}</span>}
                </div>
              )}
              {blok.top_google_text && (
                <span className="body-3 flex items-center text-v2-text-primary">{blok.top_google_text}</span>
              )}
              {blok.top_google_link.map((linkItem, index) => (
                <UtilLinkV2
                  key={index}
                  blok={{ ...linkItem, icon_right: 'chevron-right-v2' }}
                  size="md"
                  isBold={false}
                  isIconSmall={true}
                />
              ))}
            </div>
          )}

          {/* Internal reviews */}
          {!!(blok.top_review_icon?.filename || blok.top_review_title || blok.top_review_text) && (
            <div className="flex flex-col gap-3">
              {blok.top_review_icon?.filename && (
                <div className="flex">
                  <BaseImageV2
                    src={blok.top_review_icon.filename}
                    alt={blok.top_review_icon.alt}
                    breakPointsWidthMap={{ 0: 28 }}
                    className="mr-2 h-8 w-8"
                  />
                </div>
              )}
              {blok.top_review_title && <span className="heading-5 block text-v2-text-primary">{blok.top_review_title}</span>}
              {blok.top_review_text && (
                <span className="body-3 flex items-center text-v2-text-primary">{blok.top_review_text}</span>
              )}
              {blok.top_review_link?.map((linkItem, index) => (
                <UtilLinkV2
                  key={index}
                  blok={{ ...linkItem, icon_right: 'chevron-right-v2' }}
                  size="md"
                  isBold={false}
                  isIconSmall={true}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Carousel */}
      <div className="container-v2 xl:p-0">
        <Swiper
          key="review-slider"
          {...options}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          className="mb-6 !overflow-visible"
        >
          {blok.items?.map((review, index) => (
            <SwiperSlide
              key={index}
              className="!flex !h-auto w-full shrink-0 flex-col justify-between self-stretch rounded-5xl border border-v2-foreground-static-tertiary p-8  sm:p-10 "
            >
              <ItemSectionReviewsCardV2 blok={review} />

              {/* Author */}
              {review.author_name && (
                <div className="flex">
                  {review.author_image?.filename ? (
                    <BaseImageV2
                      breakPointsWidthMap={{ 0: 64 }}
                      className="mr-4 h-16 w-16 shrink-0 overflow-hidden rounded-full border border-v2-foreground-static-tertiary [&_img]:h-full [&_img]:w-full [&_img]:object-cover"
                      src={review.author_image.filename}
                      alt={review.author_image.alt}
                    />
                  ) : (
                    <div className="heading-5 mr-4 flex h-16 w-16 items-center justify-center rounded-full border border-v2-foreground-static-tertiary bg-v2-surface-2">
                      {review.author_name.charAt(0).toUpperCase()}
                    </div>
                  )}
                  <div className="flex flex-col justify-center">
                    <div className="flex items-center gap-1">
                      <span className="heading-6 text-v2-text-primary">{review.author_name}</span>
                      <Icon name="badge-check" width={20} height={20} className="" />
                    </div>
                    {review.author_text && <span className="caption-default text-v2-text-secondary">{review.author_text}</span>}
                  </div>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Bottom */}
        <div className="md:container-v2 mx-auto md:px-4">
          {!!blok.bottom_text && <div className="body-3 mb-6 text-v2-foreground-static-secondary">{blok.bottom_text}</div>}
          {!isSliderSingle && (
            <div className="flex flex-col gap-6 md:flex-row md:items-center">
              <div className={classNames('flex gap-3', { 'md:hidden': isSlideDesktopDisabled })}>
                <ItemIconButtonV2 ariaLabel="Slide Prev" icon="chevron-left-v2" onClick={prevSlide} disabled={isBeginning} />
                <ItemIconButtonV2 ariaLabel="Slide Next" icon="chevron-right-v2" onClick={nextSlide} disabled={isEnd} />
              </div>
              {!!blok.bottom_button.length && (
                <div>
                  {blok.bottom_button.map((blok: SbBlokData & ISbUtilButtonV2, index: number) => (
                    <UtilButtonV2 key={index} blok={blok} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SectionReviewsV2
